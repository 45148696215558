import { useEffect, useState } from "react";
import axios from "axios";
import { DownloadPopup } from "@bamboobox/b2commoncomponents";
import { Alert } from "@mui/material";
import CircularProgress from "@material-ui/core/CircularProgress";
import { toast } from "react-toastify";
import CloseIcon from '@mui/icons-material/Close';
import { AddCampaign } from "./AddCampaign";
import { DownloadIcon } from "../../assets/svg";
import { getSessionPermissionsForCards } from "./../../helpers/permissionForCards.helper";
// import config from "./config.json";
import { getTenantConfigMapper } from "./../../helpers/tenantConfig.helper";
import "../../assets/styles/toast.css";
const CardFooterAction = (props) => {
  console.log("props inside CardFooterAction", props);
  const [open, setOpen] = useState(false);
  const [isCampaignOpen, setIsCampaignOpen] = useState(false);
  const [column, setColumn] = useState([]);
  const [isDownload, setIsDownload] = useState(false);
  const [isActionLabel, setIsActionLabel] = useState(false);
  const [tenantConfigMapper, setTenantConfigMapper] = useState([]);

  const calculateFieldsForDownload = async (tenantConfigMapper) => {
    let i = 0;
    if (props.cardDetails?.cardObject === "ACCOUNT" && column?.length === 0) {
      let keysOfAccTenantMapper = Object.keys(tenantConfigMapper);
      keysOfAccTenantMapper = keysOfAccTenantMapper.map((obj) => ({ ...tenantConfigMapper[obj], attribute: obj })).filter((obj) => obj.hasOwnProperty("attribute"));
      keysOfAccTenantMapper = keysOfAccTenantMapper.map((tempObj) => {
        tempObj["className"] = "";
        tempObj["width"] = "200px";
        tempObj["minResizeWidth"] = null;
        tempObj["maxResizeWidth"] = null;
        tempObj["searchable"] = true;
        tempObj["editable"] = true;
        tempObj["sortable"] = true;
        tempObj["resizable"] = true;
        tempObj["id"] = i;
        tempObj["columnVisibility"] = true;
        tempObj["visible"] = tempObj?.visible || false;
        tempObj["pinned"] = false;
        tempObj["index"] = i;
        i += 1;
        return tempObj;
      });

      console.log("final mapper download", keysOfAccTenantMapper);
      setColumn(keysOfAccTenantMapper || []);

      return true;
    }
  };

  const performActionViaCardService = async (tenantId, icp, criteriaBody, actionName, objectType, resultCount, selectedCampaign, source) => {
    //testing purpose
    let body;
    let action;
    if (actionName === "addToCampaign") {
      action = "campaign";
      body = {
        programList: {
          programId: selectedCampaign.programId,
          programStatus: selectedCampaign.programStatus,
          programName: selectedCampaign.programName,
        },

        segBody: criteriaBody,
        source: source.tool,
      };
    } else {
      action = "enrichment queue";
      body = criteriaBody;
    }
    //let resp = await axios.post(`http://localhost:3000/action/performAction/${tenantId}?actionLabel=${actionName}&icp=${icp}&type=${(objectType).toLowerCase()}&countOfRecs=${resultCount}`,
    await axios
      .post(
        `${window._env_.CARD_SERVICE}/action/performAction/${tenantId}?actionLabel=${actionName}&icp=${icp}&type=${objectType.toLowerCase()}&countOfRecs=${resultCount}&offset=${props.offset}`,
        body,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        console.log("success");

        toast.success(
          <Alert severity="success">
            {" "}
            {props.cardDetails.title.split(" ")[0]} {props.cardDetails.title.split(" ")[1]} being processed to be added to {action}
          </Alert>,
          {
            closeOnClick: true,
            autoClose: true,
            closeButton: true,
            newestOnTop: true,
          }
        );
      })
      .catch((e) => {
        toast.error(<Alert severity="error">{e}</Alert>, {
          closeOnClick: true,
          autoClose: true,
          closeButton: true,
          newestOnTop: true,
        });
        console.log("error");
      });
  };

  const handleNotified = () => {
    try {
      if (props.cardDetails.notified === false) {
        let body = [{ cardUuid: props.cardId, notified: true }];
        axios.post(`${window._env_.CARD_SERVICE}/action/set/order/hide/${props.tenantId}`, body, { withCredentials: true }).then((e) => {
          console.log(e);
        });
      }
    } catch (error) {
      console.error("error in handleNotified:", error);
    }
  };

  const showLoadingToast = (message) => {
    return toast.info(
      <Alert
        // icon={
        //   // <CircularProgress
        //   //   style={{
        //   //     transform: "matrix(1, 0, 0, 1, 0, 0)",
        //   //   }}
        //   // />
        // }
        // sx={{
        //   "& .MuiAlert-icon": {
        //     width: "8.5%",
        //     marginTop: "9px",
        //   },
          // "& .MuiAlert-message": {
          //   padding: "15px 0px !important",
          // },
        // }}
        color="white"
      >
        {message}
      </Alert>,
      {
        closeOnClick: true,
        autoClose: true,
        closeButton: <CloseIcon style={{ width:"18px"}}/>,
        hideProgressBar: false,
      }
    );
  };

  const showFinalToast = (loadingToast, type, message) => {
    toast.dismiss(loadingToast);

    switch (type) {
      case "success":
        toast.success(<Alert severity="success">{message}</Alert>, {
          closeOnClick: true,
          autoClose: true,
          closeButton: <CloseIcon style={{ width:"18px"}}/>,
          newestOnTop: true,
        });
        break;
      case "fail":
        toast.error(<Alert severity="error">{message}</Alert>, {
          closeOnClick: true,
          autoClose: true,
          closeButton: <CloseIcon style={{ width:"18px"}}/>,
          newestOnTop: true,
        });
        break;
      default:
        break;
    }
  };

  const downloadCard = async (e) => {
    if (e !== undefined) {
      let setArray = {
        filters: [],
        columns: e
          .filter((item) => item.visible)
          .map((col) => {
            return {
              type: col.type,
              label: col.label,
              attribute: col.attribute?.slice(-1) === "." ? `${col.attribute + props.icp}` : `${col.attribute}`,
              field: col.field,
            };
          }),
      };

      setColumn(setArray.columns);
      console.log("props.cardDetails.title.split->" ,props.cardDetails);
      const loadingToast = showLoadingToast(`${props.count} ${props.cardDetails.title.split(" ")[1]} download in progress`);

      let page = 1;
      let buffer = "";

      while (1) {
        const response = await fetch(
          `${window._env_.UNIFIED_URL}/download/${props.tenantId}/?type=${props.cardDetails.cardObject?.toLowerCase()}s&page=${page}&limit=4000&icp=${props.icp}&filterName=&${
            props.cardDetails.cardObject?.toLowerCase() === "contact" ? "contSegId=" : "segId="
          }${props.cardDetails.filterDetails.filterId}&sortby=&sortType=&offset=&selectAll=false`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            credentials: "include",
            body: JSON.stringify(setArray),
          }
        );
        const res = await response.text();
        if (page !== 1) {
          const lines = res.split("\n");

          const newtext = lines.join("\n");
          buffer = buffer.concat(newtext);
        } else {
          buffer = buffer.concat(res);
        }

        if (page >= parseInt(props.count, 10) / 4000) {
          break;
        }
        page += 1;
      }
      const blob = new Blob([buffer], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `${props.cardDetails.cardObject?.toLowerCase()}_${new Date().toISOString()}.csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      showFinalToast(loadingToast, "success", `${props.count} ${props.cardDetails.title.split(" ")[1]} downloaded successfully`);
    }
  };

  const ButtonAction = async (e) => {
    console.log(props.cardDetails.actionLabel);
    handleNotified();
    if (props.cardDetails.actionLabel === "Download" || e === "Download") {
      const configResp = await getTenantConfigMapper(props.tenantId, props.cardDetails?.cardObject?.toLowerCase() === "account" ? "unified-profile-ui" : "ContactHub-creation");
      setTenantConfigMapper((prev) => ({ ...configResp }));
      // await calculateFieldsForDownload(configResp);
      setOpen(true);
    } else if (props.cardDetails.actionLabel === "Add to enrichment queue") {
      console.log("in here enrich");
      let actionName = "addToEnrichmentQueue";
      performActionViaCardService(props.tenantId, props.icp, props.cardDetails.filterDetails.filterBody, actionName, props.cardDetails.cardObject, props.count);
    } else if (props.cardDetails.actionLabel === "Add to campaign" || props.cardDetails.actionLabel === "Add to Campaign") {
      console.log("in here campaign");
      setIsCampaignOpen(true);
      //open campaign popup
      //let actionName = 'addToCampaign';
    }
  };

  const handleCampaign = (selectedCampaign, source) => {
    console.log("handle campaign", selectedCampaign);

    let actionName = "addToCampaign";
    console.log("resultOfCard", props.cardDetails);
    performActionViaCardService(
      props.tenantId,
      props.icp,
      props.cardDetails.filterDetails.filterBody,
      actionName,
      props.cardDetails.cardObject,
      props.count,
      selectedCampaign,
      source
    );
  };

  useEffect(() => {
    const cardObject = props.cardDetails?.cardObject?.toLowerCase();
    const actionLabel = props.cardDetails.actionLabel ? props.cardDetails.actionLabel.toLowerCase().replace(/\s/g, "_") : props.cardDetails.actionLabel;
    const permissionsForCards = getSessionPermissionsForCards();
    // console.log("permissions ===>", permissionsForCards)
    console.log("permissions in showButtons", cardObject, actionLabel, permissionsForCards);

    let isDownloadPermitted = permissionsForCards !== null && permissionsForCards[cardObject] ? permissionsForCards[cardObject].map((perm) => perm.toLowerCase()).includes("download") : false;
    isDownloadPermitted = isDownloadPermitted && !(props.cardDetails?.disableOptions?.download || false);

    console.log("isDownloadPermitted", isDownloadPermitted);

    let permissionsWithoutDownload = (() => {
      return isDownloadPermitted
        ? permissionsForCards[cardObject].filter((labels) => labels.toLowerCase() !== "download")
        : permissionsForCards !== null && permissionsForCards[cardObject]
        ? permissionsForCards[cardObject]
        : [];
    })();

    let buttonToShow = permissionsWithoutDownload
      ? permissionsWithoutDownload.filter((label) => {
          return label.toLowerCase().replace(/\s/g, "_") === actionLabel;
        })
      : [];

    setIsDownload(isDownloadPermitted);
    setIsActionLabel(buttonToShow?.length > 0);
    return;
  }, []);

  useEffect(() => {
    try {
      let i = 0;
      if (Object.keys(tenantConfigMapper).length > 0) {
        let keysOfAccTenantMapper = Object.keys(tenantConfigMapper);
        keysOfAccTenantMapper = keysOfAccTenantMapper.map((obj) => ({ ...tenantConfigMapper[obj], attribute: obj })).filter((obj) => obj.hasOwnProperty("attribute"));
        keysOfAccTenantMapper = keysOfAccTenantMapper.map((tempObj) => {
          tempObj["className"] = "";
          tempObj["width"] = "200px";
          tempObj["minResizeWidth"] = null;
          tempObj["maxResizeWidth"] = null;
          tempObj["searchable"] = true;
          tempObj["editable"] = true;
          tempObj["sortable"] = true;
          tempObj["resizable"] = true;
          tempObj["id"] = i;
          tempObj["columnVisibility"] = true;
          tempObj["type"] = tempObj?.tableName;
          tempObj["visible"] = tempObj?.visible || false;
          tempObj["pinned"] = false;
          tempObj["index"] = i;
          i += 1;
          return tempObj;
        });

        console.log("final mapper download", keysOfAccTenantMapper);
        setColumn((prev) => [...keysOfAccTenantMapper]);
      }
    } catch (e) {
      console.log("tenant config mapper is undefined card.jsx", e);
    }
  }, [tenantConfigMapper]);

  return (
    <>
      {isDownload && (
        <div className="download" onClick={() => ButtonAction("Download")}>
          <DownloadIcon />
        </div>
      )}
      {isActionLabel && (
        <div className="action" onClick={ButtonAction}>
          {props.cardDetails.actionLabel}
        </div>
      )}
      {open && <DownloadPopup object={"Card"} columns={column} setColumn={downloadCard} count={"3"} open={open} setOpen={setOpen} getDownlaod={downloadCard} />}
      <AddCampaign
        tenantId={props.tenantId}
        icp={props.icp}
        count={props?.cardDetails?.title?.split(" ")[0]}
        Type={props.cardDetails.cardObject}
        open={isCampaignOpen}
        setOpen={setIsCampaignOpen}
        onConfirm={handleCampaign}
        cardResult={props.cardDetails}
      />
    </>
  );
};

export default CardFooterAction;
